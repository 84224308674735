<template>
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-8 col-sm-12">
        <div class="card p-md-5 p-2">
          <div class="card-body">
            <h1>Kedves {{firstName}}!</h1>
            <p>Üdvözlünk benneteket a <strong>Neumann János Egyetemen, Kecskeméten</strong>!<br>
              Most a Neumann120 Középiskolai Gazdasági Vetélkedőben vagytok. Egy kérdésünk van:<br>
              <strong>A Te csapatod vajon kijut az egyetem gazdaságtudományi karáról?</strong><br>
              Siessetek és tegyétek magatokat próbára!<br>
              Selejtező játék időtartama: október 16. 18:00 - október 29. 23:59<br>
              Selejtező eredményhirdetés: október 31. 16:00</p>
            <p>A helyezést elsődlegesen a szerzett pontok alapján határozzuk meg, pontegyenlőség
              esetén a kijutás pontos dátuma a meghatározó (aki korábban kijutott, az előrébb kerül
              a tabellán, pont, mint egy valódi szabadulószobában).</p>
            <p>A döntőbe maximum 2 csapat kerülhet egy iskolából (a csapatkapitány iskolája alapján).</p>
            <p>A játékkal kapcsolatos kérdéssel, problémával kapcsolatban keress minket a support@ecosim.hu címen.</p>
            <p><a href="https://docs.ecosim.hu/files/nje_neumann_120_gazdasagi_vetelkedo_2023_teljes_jatekszabalyzat" target="_blank" rel="noreferrer">Játékszabály</a></p>
            <div class="text-end">
              <button class="btn btn-primary" @click="start">Vágjunk bele!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Welcome',
  props: ['initData','contents'],
  data(){
    this.initData.bigImage = 10
    return{
      firstName : this.initData.user.firstName
    }
  },
  methods:{
    start(){
      this.initData.loader = true

      this.$axios.post('/api/v1/user/start')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.user.status = responseData.data.status
              this.initData.user.questionData = responseData.data.questionData
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }

}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,0.9);
}
.card-body a{
  color: #B40041;
  text-decoration: none;
}
</style>
