<template>
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-8 col-sm-12">
        <div class="card p-md-5 p-2">
          <div class="card-body">
            <h2>Kedves Játékosok!</h2>

            <p>Gratulálunk, az összes szobából sikerült kijutnotok, ami azt jelenti, hogy a feladatokat sikeresen
            megoldottátok!<br>
            Eredményhirdetés 2023. október 31-én 16 órakor! Figyeljétek a verseny weboldalát, a
            postafiókotokat, hogy megtudjátok, hogy a döntőbe bekerült-e a csapatotok.
            </p>
            <p>A helyezést elsődlegesen a szerzett pontok alapján határozzuk meg, pontegyenlőség
            esetén a kijutás pontos dátuma a meghatározó (aki korábban kijutott, az előrébb kerül
            a tabellán, pont, mint egy valódi szabadulószobában).
            </p>
            <p>A döntőbe maximum 2 csapat kerülhet egy iskolából (a csapatkapitány iskolája alapján).</p>
            <p>A játékkal kapcsolatos kérdéssel, problémával kapcsolatban keress minket a <a href="mailto:support@ecosim.hu">support@ecosim.hu</a> címen.</p>
            <p>További információ a Neumann 120 középiskolai vetélkedőről a <a href="www.gtk.nje.hu" target="_blank" rel="noreferrer">www.gtk.nje.hu</a> weboldalon található.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StoryEnd',
  props: ['initData','contents'],
  data(){
    this.initData.bigImage = 11
  },
  computed:{

  },
  methods:{

  }
}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,0.9);
}
.card-body a{
  color: #B40041;
  text-decoration: none;
}
</style>