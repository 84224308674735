<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7">
        <div class="card p-md-5 p-2">
          <div class="card-body">
            <h1>Üdvözlünk benneteket a <strong>Neumann János Egyetemen, Kecskeméten!</strong></h1>
            <p>Most a Neumann120 Középiskolai Gazdasági Vetélkedőben vagytok. Egy kérdésünk van:<br>
              <strong>A Te csapatod vajon kijut az egyetem gazdaságtudományi karáról?</strong></p>
            <p>Siessetek és tegyétek magatokat próbára!</p>
            <p>Selejtező játék időtartama: október 16. 18:00 - október 29. 23:59<br>
              Selejtező eredményhirdetés: október 31. 16:00</p>
            <p>A helyezést elsődlegesen a szerzett pontok alapján határozzuk meg, pontegyenlőség
            esetén a kijutás pontos dátuma a meghatározó (aki korábban kijutott, az előrébb kerül
            a tabellán, pont, mint egy valódi szabadulószobában).</p>
            <p>A döntőbe maximum 2 csapat kerülhet egy iskolából (a csapatkapitány iskolája alapján).</p>
            <p>A játékkal kapcsolatos kérdéssel, problémával kapcsolatban keress minket a <a href="mailto:support@ecosim.hu">support@ecosim.hu</a> címen.</p>
            <p>
              <a>Játékszabály</a>
            </p>
            <h2>Bejelentkezés</h2>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="email" class="form-label">E-mail cím*</label>
                <input class="form-control" type="text" id="email" :class="emailError ? 'is-invalid' : ''" v-model="email" placeholder="E-mail cím" maxlength="200" autofocus aria-describedby="emailHelp">
                <div v-if="emailError" class="small text-danger">
                  {{ emailError }}
                </div>
                <div id="emailHelp" class="form-text text-end">A regisztrációkor megadott e-mail cím</div>
              </div>
              <div class="mb-5">
                <label for="password" class="form-label">Jelszó*</label>
                <input class="form-control" type="password" id="password" :class="passwordError ? 'is-invalid' : ''" v-model="password" placeholder="Jelszó" maxlength="200" aria-describedby="passwordHelp">
                <div v-if="passwordError" class="small text-danger">
                  {{ passwordError }}
                </div>
                <div id="passwordHelp" class="form-text text-end"><a href="https://myaccount.ecosim.hu/elfelejtett_jelszo" target="_blank" rel="noreferrer">Elfelejtett jelszó</a></div>
              </div>
              <div class="mb-3 text-end">
                <button type="submit" class="btn btn-primary">Bejelentkezés</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Response} from "@/classes/response";
import {Validate} from "@/classes/validate";

export default {
  name: 'Login',
  props: ['initData','contents'],
  data(){
    this.initData.bigImage = 1
    return{
      email : null,
      emailError : null,
      password : null,
      passwordError : null
    }
  },
  methods:{
    login(){
      let validate = new Validate()

      //EMAIL
      this.emailError = validate.emptyField(this.email,'Add meg az e-mail címed.')

      //PASSWORD
      this.passwordError = validate.emptyField(this.password,'Add meg a jelszavad.')

      if (this.emailError || this.passwordError) {
        return false;
      }

      this.initData.loader = true
      let postData = {
        'email' : this.email,
        'password' : this.password
      }
      this.$axios.post('/api/v1/user/login', postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.user.status = responseData.data.status
              this.initData.user.teamName = responseData.data.teamName
              this.initData.user.firstName = responseData.data.firstName
              this.initData.user.isTestTeam = responseData.data.isTestTeam
              localStorage.setItem('n120_session_hash',responseData.data.sC)
              this.$axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('n120_session_hash')}`
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }

}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,0.9);
}
</style>
