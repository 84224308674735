<template>
  <div class="spinner-container" v-show="loaderVisible">
    <div class='spinner-loader'>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InitLoader',
  props: ['initData'],
  computed: {
    loaderVisible : function(){
      return this.initData.initLoader
    }
  }
}
</script>
<style scoped>
.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color:rgba(0, 0, 0, 0.5);
}
.spinner-loader{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color:rgba(0, 0, 0, 0.5);
}
.spinner-loader:before {
  content: '';
  box-sizing: border-box;
  position: fixed;
  top: 25%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #E8E7E9;
  border-top-color: #00AEEF;
  animation: spinner .6s linear infinite;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
</style>