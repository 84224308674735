<template>
  <footer>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-4">
          <ul class="nav">
            <li class="nav-item d-flex">
              <a href="">Adatvédelmi nyilatkozat</a>
            </li>
            <li v-if="isTestTeam==1" class="text-danger">
              <a @click="Game.methods.restart(this.$parent)">Development restart</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-4 text-center">
          <a href="https://www.ecosim.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/images/ecosim_white_sign.png" alt="Ecosim" />
          </a>
        </div>
        <div class="col-12 col-md-4 text-end">
          Neumann János Egyetem Gazdaságtudományi Kar
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import Game from "@/views/Game"

export default {
  name: 'Footer',
  props: ['initData','contents'],
  components:{
    Game
  },
  data(){
    return{
      Game
    }
  },
  computed:{
    isTestTeam(){
      return this.initData.user.isTestTeam
    }
  }
}
</script>
<style scoped>
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--footerBg);
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #fff;
}
footer a{
  color: #fff;
  text-decoration: none;
}
.container{
  max-width: 1280px;
}
.row{
  height: 5rem;
}
.nav li:first-child{
  margin-right: 1rem;
}
</style>